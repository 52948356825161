import React from 'react'

import { ContactUsContainer, ContactUsContentContainer, Logo, Address, Section, SectionHeader, ContactItem } from './index.styled'
import { WhatsApp, Instagram } from '../Svg'

import LogoImage from '../../assets/img/logo.png'
import Maps from '../../assets/img/maps.png'

export default function ContactUs (): React.ReactElement {
  return (
    <ContactUsContainer>
      <ContactUsContentContainer>
        {/* Information */}
        <Section>
          <SectionHeader style={{ marginBottom: 16 }}>
            <Logo src={LogoImage} />
          </SectionHeader>
          <Address style={{ marginRight: 16 }}>
            Bearsih merupakan perusahaan yang melayani pencucian berbagai furnitur rumah, baik itu sofa, meja, kursi, karpet, bahkan springbed. <br /><br />
            Jl. Bintara 17 No.30, RT.001/RW.013, Bintara, Kec. Bekasi Bar., Kota Bks, Jawa Barat 17134
            {/* Bintara 11 RT 10 / RW 02 Nomor 6, Bintara Jaya, Bekasi Barat. */}
          </Address>
        </Section>

        {/* Contact Info */}
        <Section>
          <SectionHeader>Hubungi Kami</SectionHeader>
          <ContactItem onClick={() => window.open('https://api.whatsapp.com/send?phone=6287882036426')}>
            <WhatsApp size={20} />
            +62 878 8203 6426
          </ContactItem>
          <ContactItem onClick={() => window.open('https://instagram.com/bearsih')}>
            <Instagram size={18} />
            @bearsih
          </ContactItem>
        </Section>

        {/* Maps */}
        <Section>
          <SectionHeader>Temukan Kami</SectionHeader>
          <div style={{ width: '100%' }}>
            <img
              onClick={() => window.open('https://maps.app.goo.gl/DzJniZofPyaiVgYP6')}
              src={Maps}
              style={{ cursor: 'pointer', width: '100%' }}
            />
          </div>
        </Section>
      </ContactUsContentContainer>
    </ContactUsContainer>
  )
}
