import styled from 'styled-components'
import Container from '../Container'

const ContactUsContainer = styled.div`
  background: #3C405B;
  color: #FFFFFF;
  width: 100%;
  padding: 72px 0px;
`

const ContactUsContentContainer = styled(Container)`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 16px;
  height: 100%;
`

const Logo = styled.img`
  height: 32px
`

const Address = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
`

const Section = styled.div`
  margin-bottom: 32px;
  width: 33%;

  @media(max-width: 768px) {
    width: 50%;
  }

  @media(max-width: 425px) {
    width: 100%;
  }
`

const SectionHeader = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #F2CC8E;
  margin-bottom: 32px;
  display: block;
`

const ContactItem = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0px;
  }

  svg {
    margin-right: 8px;
  }
`

export default {}
export { ContactUsContainer, ContactUsContentContainer, Logo, Address, Section, SectionHeader, ContactItem }
