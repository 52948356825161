import styled from 'styled-components'
import Container from '../components/Container'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface TruncateProps {
  lineCount?: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ReadMoreProps {
  from?: string;
}

const Hero = styled.div`
  background: #3C405B;
  color: white;
  display: flex;
  position: relative;
  padding: 128px 0 72px;
`

const HeroContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 16px;
  width: 100%;
  
  @media(max-width: 768px) {
    justify-content: center;
  }
`

const HeroIllustrationContainer = styled.div`
  @media(max-width: 768px) {
    display: none
  }
  `

const HeroBodyContainer = styled.div`
  @media(max-width: 768px) {
    text-align: center
  }
`

const HeroTitle = styled.h1`
  color: #F2CC8E;
  font-size: 36px;
  text-transform: uppercase;
  margin: 0px;
  transition: all .3s ease;
  
  @media(max-width: 425px) {
    font-size: 28px;
  }
`

const HeroBody = styled.p`
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  margin-top: 16px;
  max-width: 480px;
  transition: all .3s ease;

  @media(max-width: 425px) {
    font-size: 16px;
  }
`

const HeroButton = styled.button`
  align-items: center;
  background: transparent;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  margin-top: 32px;
  outline: none;
  padding: 10px 20px;
`

const Introduction = styled(Hero)`
  background: white;
  color: #3C405B;
  padding: 72px 0;
`

const ImgIntro = styled.img`
  @media(max-width: 768px) {
    display: none
  }
`

const IntroductionBodyContainer = styled.div`
  margin: 0 24px;

  @media(max-width: 768px) {
    margin: 0 16px;
  }
`

const IntroductionTitle = styled.h1`
  line-height: 48px;

  @media(max-width: 425px) {
    font-size: 28px;
  }
`

const IntroductionBody = styled.p`
  line-height: 28px;
  margin: 0;
`

const Truncate = styled.span<TruncateProps>`
  -webkit-line-clamp: ${(props) => props.lineCount || 3};
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: 14px;
  font-weight: 300;
  margin: 0 0 1em 0;
  overflow: hidden;
`

const ReadMore = styled.p<ReadMoreProps>`
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  // opacity: ${(props) => props.from === 'detail' ? 1 : 0};
  opacity: 0;
  transition: all .3s ease;

  @media(max-width: 1024px) {
    opacity: 1;
  }
`

const ServicesContainer = styled(HeroContainer)`
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`

const Service = styled.div`
  background: #F5F5F7;
  color: #3C405B;
  padding: 64px 0 72px;
`

const ServiceTitle = styled.h1`
  line-height: 48px;
  align-items: center;
  display: table;
  margin: 0 auto 80px;
`

const TestimoniesContainer = styled(HeroContainer)`
  flex-direction: column;

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`

const Testimoni = styled.div`
  color: #3C405B;
  padding: 64px 0 72px;
`

const TestimoniTitle = styled.h1`
  align-items: center;
  display: table;
  line-height: 48px;
  margin: 0 auto 80px;
`

export default {}
export {
  Truncate, ReadMore,
  Hero, HeroContainer, HeroTitle, HeroBodyContainer, HeroBody, HeroButton, HeroIllustrationContainer,
  Introduction, ImgIntro, IntroductionBodyContainer, IntroductionTitle, IntroductionBody,
  Service, ServiceTitle, ServicesContainer,
  TestimoniesContainer, Testimoni, TestimoniTitle
}
