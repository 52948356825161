import styled from 'styled-components'
import Container from '../Container'

const FooterContainer = styled.div`
  background: #3C405B;
  color: #F2CC8E;
  height: 64px;
  width: 100%;
`

const FooterContentContainer = styled(Container)`
  align-items: center;
  display: flex;
  height: 100%;
  padding: 0px 16px;

  @media(max-width: 425px) {
    justify-content: center;
  }
`

const FooterBody = styled.p`
  align-items: center;
  display: flex;
  font-size: 16px;
  transition: all .3s ease;

  @media(max-width: 425px) {
    font-size: 14px;
  }
  
  @media(max-width: 280px) {
    font-size: 12px;
  }
`

export default {}
export { FooterContainer, FooterContentContainer, FooterBody }
