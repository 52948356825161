import React from 'react'

import { FooterContainer, FooterContentContainer, FooterBody } from './index.styled'
import { Copyright } from '../Svg'

export default function Footer (): React.ReactElement {
  const date = new Date()
  const year = date.getFullYear()

  return (
    <FooterContainer>
      <FooterContentContainer>
        <FooterBody>Copyright&nbsp;<Copyright />&nbsp;{year}. All rights reserved</FooterBody>
      </FooterContentContainer>
    </FooterContainer>
  )
}
