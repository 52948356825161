import React from 'react'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

interface MetaProps {
  title?: string,
  description?: string,
  image?: string,
  url?: string,
  keywords?: []
}

const Meta = ({ title, description, image, url, keywords }: MetaProps): React.ReactElement => (
  <StaticQuery
    query={detailsQuery}
    render={(data) => {
      const metaDescription = description || data.site.siteMetadata.description
      const metaTitle = title || data.site.siteMetadata.title
      const metaImage = image || data.site.siteMetadata.image
      const metaUrl = url || data.site.siteMetadata.url
      const metaKeywords = keywords || [
        'cuci sofa', 'cuci torrent', 'service ac', 'cuci furnitur',
        'cuci karpet', 'jasa cuci', 'cuci meja', 'cuci kursi',
        'cuci springbed', 'cuci bersih', 'jasa cuci harga terjangkau', 'cuci murah'
      ]

      return (
        <Helmet
          title={title}
          meta={[
            {
              name: 'description',
              content: metaDescription
            },
            {
              name: 'image',
              content: metaImage
            },
            {
              name: 'og:title',
              content: metaTitle
            },
            {
              name: 'og:description',
              content: metaDescription
            },
            {
              name: 'og:type',
              content: 'website'
            },
            {
              name: 'og:image',
              content: metaImage
            },
            {
              name: 'og:url',
              content: metaUrl
            }
          ].concat(
            metaKeywords && metaKeywords.length > 0 ? {
              name: 'keywords',
              content: metaKeywords.join(', ')
            } : []
          )}
        />
      )
    }}
  />
)

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        image
        url
      }
    }
  }
`

export default Meta
