import styled, { css } from 'styled-components'
import Container from '../Container'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface NavProps {
  useShadow?: boolean;
}

const Nav = styled.div<NavProps>`
  height: 66px;
  background: #3C405B;
  border-top: 6px solid #F2CC8E;
  color: white;
  display: flex;
  margin: 0;
  padding: 0;
  position: fixed;
  transition: all .3s ease;
  width: 100%;
  z-index: 100;

  ${(props) => props.useShadow && css`
    box-shadow: 0 0px 10px 2px #2d2d2d;
  `}
`

const NavbarContainer = styled(Container)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;
  width: 100%;
  
  @media (max-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`

const Logo = styled.img`
  cursor: pointer;
  height: 32px;
`

const Link = styled.a`
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  line-height: 122.6%;
`

const Links = styled.div`
  margin-left: auto;
  opacity: 1;
  transition: all .3s ease;

  ${Link} {
    margin: 0px 8px;

    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }

  @media(max-width: 640px) {
    display: none;
    opacity: 0;
  }
`

const LinksMenu = styled.div`
  display: none;
  opacity: 0;

  @media(max-width: 640px) {
    display: block;
    opacity: 1;
  }
`

const Modal = styled.div`
  background: white;
  box-shadow: 0 0px 10px 2px #2d2d2d;
  position: fixed;
  padding-bottom: 16px;
  width: 100%;
  z-index: 100;
`

const CloseContainer = styled.div`
  border-top: 6px solid #F2CC8E;
  padding: 16px;
  text-align: right;
`

const PhoneLink = styled.div`
  color: #3C405B;
  cursor: pointer;
  padding: 16px;
`

export default {}
export { Nav, Link, Links, LinksMenu, Logo, NavbarContainer, Modal, CloseContainer, PhoneLink }
