import React from 'react'
import { createGlobalStyle } from 'styled-components'
import 'normalize.css'

interface Props {
  children: React.ReactElement | React.ReactElement[];
}

const Global = createGlobalStyle`
  body {
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  }
`

export default function Default (props: Props): React.ReactElement {
  const { children } = props

  return (
    <div>
      <Global />
      {children}
    </div>
  )
}
