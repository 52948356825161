import React, { useEffect, useState } from 'react'
import {
  Nav, Link, Links, LinksMenu, Logo, NavbarContainer,
  Modal, CloseContainer, PhoneLink
} from './index.styled'

import LogoImage from '../../assets/img/logo.png'
import { Menu, Close } from '../Svg'

interface props {
  onClickService?: any,
  onClickTestimoni?: any,
  onClickContact?: any
}

export default function Navbar ({ onClickService, onClickTestimoni, onClickContact }: props): React.ReactElement {
  const [useShadow, setUseShadow] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)

  const handleScrollingEvent = () => {
    if (window.scrollY > 0) setUseShadow(true)
    else setUseShadow(false)
  }

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu)
  }

  const handleClick = (onClick: any) => {
    onClick()
    setTimeout(() => {
      handleOpenMenu()
    }, 1000)
  }

  useEffect(() => {
    document.body.style.overflowY = openMenu ? 'hidden' : 'auto'
  }, [openMenu])

  useEffect(() => {
    window.addEventListener('scroll', handleScrollingEvent)
    return () => {
      window.removeEventListener('scroll', handleScrollingEvent)
    }
  })

  return (
    openMenu ? (
      <Modal>
        <CloseContainer onClick={() => handleOpenMenu()}>
          <Close />
        </CloseContainer>
        <PhoneLink onClick={() => handleClick(onClickService)}>
          Layanan Kami
        </PhoneLink>
        <PhoneLink onClick={() => handleClick(onClickTestimoni)}>
          Testimoni
        </PhoneLink>
        <PhoneLink onClick={() => handleClick(onClickContact)}>
          Tentang Kami
        </PhoneLink>
      </Modal>
    ) : (
      <Nav useShadow={useShadow}>
        <NavbarContainer>
          <Logo src={LogoImage} onClick={() => { window.location.href = '/' }} />
          <LinksMenu onClick={() => handleOpenMenu()}>
            <Menu />
          </LinksMenu>
          <Links>
            <Link onClick={onClickService}>Layanan Kami</Link>
            <Link onClick={onClickTestimoni}>Testimoni</Link>
            <Link onClick={onClickContact}>Tentang Kami</Link>
            <Link onClick={() => window.open('https://api.whatsapp.com/send?phone=6287882036426')}>Hubungi Kami</Link>
          </Links>
        </NavbarContainer>
      </Nav>
    )
  )
}
